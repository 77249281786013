import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import styled, { keyframes } from 'styled-components';

import { Section } from '../components/page-elements';
import Container from '../components/container';
import Button from '../components/buttons';

import Spacer from '../components/spacer';
import {
  PreHeader,
  D2,
  D3,
  NavLabel,
  Technical,
  TechnicalSmall,
  D1,
} from '../theme/typography';
import VideoModal from '../components/videoModal';

const PageHeader = styled(D2)`
  color: white;
  margin-bottom: 24px;
`;

const Center = styled.div`
  display: grid;
  place-items: center;
`;

const TestingPage = ({ data }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { sanityVideo } = data;

  return (
    <>
      <Section dark={true}>
        <Container>
          <Center>
            <Spacer size={240} />
            <PageHeader>Video Modal Tester</PageHeader>
            <Button
              label='Play Video'
              type='primary'
              onClick={() => setIsModalOpen(true)}
            />
            <Spacer size={240} />
          </Center>
          <VideoModal
            isModalOpen={isModalOpen}
            video={sanityVideo}
            onClose={() => setIsModalOpen(false)}
            productRoutes={data.allSanityProductPage.nodes}
            solutionRoutes={data.allSanityIndustryPage.nodes}
          />
        </Container>
      </Section>
    </>
  );
};

// GraphQL Query
export const testingPageQuery = graphql`
  query MyQuery {
    sanityVideo(client: { company: { eq: "TV3" } }) {
      ...SanityVideoFragment
      relatedVideos {
        ...SanityVideoFragment
      }
      productTags {
        slug {
          current
        }
        icon {
          name
        }
      }
      client {
        industryTags {
          slug {
            current
          }
          svgLogo
          label
        }
      }
    }
    allSanityProductPage {
      nodes {
        productTag {
          slug {
            current
          }
        }
      }
    }
    allSanityIndustryPage {
      nodes {
        industryTag {
          slug {
            current
          }
        }
      }
    }
  }
`;

export default TestingPage;
